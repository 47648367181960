import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import '../css/styles.scss';
import { Helmet } from 'react-helmet';

const Icon = ({ name, color }) => {
    const iconColor = color ? `has-text-${color}` : '';

    return (
        <span className={`icon ${iconColor}`}>
            <i className={name}></i>
        </span>
    );
};

Icon.propTypes = {
    name: PropTypes.string.isRequired,
    color: PropTypes.string,
};

const Project = ({ url, title, isDown = false, notReady = false }) => {
    return (
        <React.Fragment>
            {isDown ? <Icon name="fas fa-exclamation-triangle" color="danger" /> : null}
            {notReady ? <Icon name="far fa-hourglass" color="info" /> : null}
            <a href={url}>{url.replace(/^https?:\/\//, '')}</a> - {title}
        </React.Fragment>
    );
};

Project.propTypes = {
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    isDown: PropTypes.bool,
    notReady: PropTypes.bool,
};

const IndexPage = () => {
    const startYear = 2020;
    const currentYear = moment().year();

    const yearStr = currentYear === startYear ? `${startYear}` : `${startYear} - ${currentYear}`;

    return (
        <React.Fragment>
            <Helmet>
                <title>perunHQ - Milan Herda</title>
            </Helmet>
            <nav className="navbar is-dark" role="navigation" aria-label="main navigation">
                <div className="navbar-brand">
                    <span className="navbar-item is-size-4 has-text-weight-bold">perunHQ</span>
                    <span className="navbar-item">Milanov hlavný stan</span>
                </div>
                <div id="menu" className="navbar-menu">
                    <div className="navbar-end">
                        <a className="navbar-item" href="https://milan.perunhq.org">
                            Milan Herda
                        </a>
                    </div>
                </div>
            </nav>
            <div className="container mt-4 mx-4">
                <h1 className="title is-1 is-hidden-mobile">perunHQ - Milanov hlavný stan</h1>
                <div className="notification is-info">
                    Ahoj, volám sa Milan a <strong>perunHQ</strong> je môj internetový domov a
                    rozcestník pre všetky moje projekty. <i>HQ</i> je skratkou od anglického{' '}
                    <i>headquarters</i> a <i>perún</i> je jedna z mojich prezývok, ktoré som kedysi
                    používal na internete.
                </div>
                <div className="columns">
                    <div className="column is-6">
                        <h2 className="title is-2">Osobné stránky</h2>
                        <ul className="ul">
                            <li>
                                <Project url="https://milan.perunhq.org" title="domovská stránka" />
                            </li>
                            <li>
                                <Project
                                    url="https://hrad.perunhq.org"
                                    title="programátorský blog"
                                />
                            </li>
                            <li>
                                <Project url="https://twitter.com/moriquend" title="môj Twitter" />
                            </li>
                        </ul>
                    </div>
                    <div className="column is-6">
                        <h2 className="title is-2">Prednášky, slajdy, školenia</h2>
                        <ul className="ul">
                            <li>
                                <Project
                                    url="https://programatorske-skolenia.sk"
                                    title="moje školenia, ktoré ponúkam firmám"
                                />
                            </li>
                            <li>
                                <Project
                                    url="https://slides.com/milanherda"
                                    title="novšie prezentácie na slides.com"
                                />
                            </li>
                            <li>
                                <Project
                                    url="https://prezentacie.perunhq.org"
                                    title="zoznam starších prezentácií"
                                />
                            </li>
                            <li>
                                <Project
                                    url="https://www.youtube.com/channel/UC9iOuyKbmY2xqMzpttmP4pg"
                                    title="môj youtube kanál"
                                />
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="columns">
                    <div className="column is-6">
                        <h2 className="title is-2">Grid Battles</h2>
                        <ul className="ul">
                            <li>
                                <Project
                                    url="https://www.gridbattles.com/"
                                    title="taktická ťahová hra pre dvoch hráčov"
                                />
                            </li>
                            <li>
                                <Project
                                    url="https://blog.gridbattles.com/"
                                    title="blog o vývoji hry"
                                />
                            </li>
                        </ul>
                    </div>
                    <div className="column is-6">
                        <h2 className="title is-2">Feudarium</h2>
                        <ul className="ul">
                            <li>
                                <Project
                                    url="https://feudarium.com"
                                    title="real-time webová strategická hra"
                                />
                            </li>
                            <li>
                                <Project
                                    url="https://blog.feudarium.com"
                                    title="blog o vývoji hry"
                                />
                            </li>
                            <li>
                                <Project url="https://twitter.com/feudarium" title="Twitter účet" />
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="columns">
                    <div className="column is-6">
                        <h2 className="title is-2">Iné projekty</h2>
                        <ul className="ul">
                            <li>
                                <Project
                                    url="https://waree.perunhq.org"
                                    title="ťahová wargame pre dvoch hráčov, predchodca hry Grid Battles. Už nie je online."
                                    isDown
                                />
                            </li>
                            <li>
                                <Project
                                    url="https://sf1.perunhq.org"
                                    title="Strike Force One - webová verzia papierovej wargame"
                                />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <footer className="footer mt-6">
                <div className="content has-text-centered">
                    <p>
                        &copy; {yearStr} <a href="https://milan.perunhq.org">Milan Herda</a>
                    </p>
                </div>
            </footer>
        </React.Fragment>
    );
};

export default IndexPage;
